<template>
  <div class="pa-4">
    <div v-if="ticket != undefined && ticket != null">
      <v-select
        :items="lottery_types"
        filled
        label="Lottery Type"
        v-model="ticket.type_name"
        :error-messages="ticket.error"
        :error="ticket.error != ''"
      ></v-select>
      <div v-for="(item, index) in ticket.numbers" :key="index">
        <v-text-field
          :label="'Ticket numbers #' + (index + 1).toString()"
          v-model="ticket.numbers[index].play"
          v-mask="'##-##-##-##-## (##)'"
          :error-messages="ticket.numbers[index].error"
          :error="ticket.numbers[index].error != ''"
        ></v-text-field>
      </div>
      <v-text-field
        label="First Draw date (YYYY-MM-DD)"
        v-model="draw_date"
        @click="openDatePicker(ticket.draw_date)"
        readonly
        :error-messages="ticket.dates_error"
        :error="ticket.dates_error != ''"
      ></v-text-field>
      <v-text-field
        label="Last Draw date (YYYY-MM-DD)"
        v-model="last_draw_date"
        @click="openDatePicker(ticket.last_draw_date)"
        readonly
        :error-messages="ticket.dates_error"
        :error="ticket.dates_error != ''"
      ></v-text-field>
      <v-checkbox
        v-model="ticket.multiplier"
        :label="ticket.type_name == 'megamillions' ? 'Megaplier' : 'Powerplay'"
        :error-messages="ticket.multiplier_error"
        :error="ticket.multiplier_error != ''"
      ></v-checkbox>
      <v-text-field
        label="Display Date (YYYY-MM-DD)"
        v-model="display_date"
        @click="openDatePicker(ticket.display_date)"
        readonly
        v-if="type == 'free'"
      ></v-text-field>
      <v-text-field
        label="Display Time (HH:MM)"
        v-model="display_time"
        @click="openTimePicker()"
        readonly
        v-if="type == 'free'"
      ></v-text-field>
      <v-text-field
        label="Amount"
        v-model="ticket.amount"
        type="number"
        v-if="type == 'free'"
      ></v-text-field>

      <v-dialog
        transition="dialog-top-transition"
        v-model="dialogDate"
        max-width="300"
      >
        <template>
          <v-date-picker
            v-model="pickerDateStr"
            @change="closeDatePicker()"
          ></v-date-picker>
        </template>
      </v-dialog>

      <v-dialog
        transition="dialog-top-transition"
        v-model="dialogTime"
        max-width="300"
      >
        <template>
          <v-time-picker
            v-model="display_time"
            class="mt-4"
            format="24hr"
            @change="closeTimePicker()"
          ></v-time-picker>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
  directives: { mask },
  props: ["ticket", "type"],
  data() {
    return {
      lottery_types: [
        { text: "Mega Millions", value: "megamillions" },
        { text: "Powerball", value: "powerball" },
      ],
      draw_date: "",
      last_draw_date: "",
      display_date: "",
      display_time: "",
      lottery_type: "megamillions",
      numbers: "",
      amount: 0,
      dialogDate: false,
      dialogTime: false,
      pickerDateStr: null,
      pickerDateObj: null,
      //pickerDateType: "",
    };
  },
  mounted() {
    this.$root.$on("ticket_updated", () => {
      this.draw_date = this.$root.$formatDateISO8601(this.ticket.draw_date);
      this.last_draw_date = this.$root.$formatDateISO8601(
        this.ticket.last_draw_date
      );
    });

    this.draw_date = this.$root.$formatDateISO8601(this.ticket.draw_date);
    this.display_date = this.$root.$formatDateISO8601(this.ticket.display_date);

    if (this.ticket.display_date != undefined) {
      var hours = this.ticket.display_date.getHours().toString();
      if (this.ticket.display_date.getHours() < 10) {
        hours = "0" + hours;
      }
      var minutes = this.ticket.display_date.getMinutes().toString();
      if (this.ticket.display_date.getMinutes() < 10) {
        minutes = "0" + minutes;
      }
      this.display_time = hours + ":" + minutes;
    }
  },

  methods: {
    openDatePicker(date) {
      //this.pickerDateType = type;
      this.dialogDate = true;
      // if (this.pickerDateType == "draw") {
      this.pickerDateObj = date;
      this.pickerDateStr = this.$root.$formatDateISO8601(date);
      // } else if (this.pickerDateType == "display") {
      //   this.pickerDate = this.$root.$formatDateISO8601(
      //     this.ticket.display_date
      //   );
      // }
    },
    closeDatePicker() {
      this.dialogDate = false;
      var splittedDate = this.pickerDateStr.split("-");

      this.pickerDateObj.setDate(1);
      this.pickerDateObj.setFullYear(parseInt(splittedDate[0]));
      this.pickerDateObj.setMonth(parseInt(splittedDate[1]) - 1);
      this.pickerDateObj.setDate(parseInt(splittedDate[2]));

      if (this.pickerDateObj == this.ticket.draw_date) {
        this.draw_date = this.pickerDateStr;
      } else if (this.pickerDateObj == this.ticket.last_draw_date) {
        this.last_draw_date = this.pickerDateStr;
      } else if (this.pickerDateObj == this.ticket.display_date) {
        this.display_date = this.pickerDateStr;
      }
    },
    openTimePicker() {
      this.dialogTime = true;
      this.display_time =
        this.ticket.display_date.getHours().toString() +
        ":" +
        this.ticket.display_date.getMinutes().toString();
    },
    closeTimePicker() {
      this.dialogTime = false;
      var timeSplitted = this.display_time.split(":");
      this.ticket.display_date.setHours(timeSplitted[0]);
      this.ticket.display_date.setMinutes(timeSplitted[1]);
    },
  },
};
</script>